<ng-container *ngIf="project && dataReady">

  <header>
    <h2>{{ 'project.create.' + specificationFormRule?.keys?.header | translate }}</h2>
  </header>

  <form [formGroup]="specificationFormGroup">

    <section>
      <h4>{{ 'project.create.' + specificationFormRule?.keys?.info | translate }}</h4>
      <div class="basic-inputs">

        <!-- Asset Name -->
        <ng-container *ngIf="specificationFormRule?.fields?.name?.display">
          <div class="input-50">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.name?.required }" for="name">
              {{ 'project.create.' + specificationFormRule?.fields?.name?.headingKey | translate }}
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              data-testid="project-name"
              formControlName="nameControl">
            <oes-form-error-message
              [showRequired]="specificationFormGroup?.controls?.nameControl?.touched &&
                              specificationFormGroup?.controls?.nameControl?.invalid">
            </oes-form-error-message>
          </div>
        </ng-container>

        <!-- Asset Type -->
        <ng-container *ngIf="specificationFormRule?.fields?.projectType?.display">
          <div class="input-50">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.projectType?.required }" for="projectType">
              {{ 'project.create.type' | translate }}
            </label>
            <div class="select-wrapper">
              <select formControlName="projectTypeControl"
                      class="form-control"
                      id="projectType"
                      data-testid="project-type">
                <option *ngFor="let projectType of projectTypes"
                        [ngValue]="projectType.id">
                  {{ 'project.types.' + projectType.id | translate }}
                </option>
              </select>
            </div>
          </div>
        </ng-container>

        <!-- Programs -->
        <ng-container *ngIf="programs?.length > 0">
          <div class="input-33">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.program?.required }" for="program">
              {{ 'project.create.program' | translate }}
            </label>
            <div class="select-wrapper">
              <select formControlName="programControl"
                      id="program"
                      data-testid="project-program"
                      class="form-control"
                      [ngClass]="{'select-w-placeholder': !specificationFormGroup?.controls['programControl']?.value}">
                <option [selected]="true" [ngValue]="undefined">
                  {{ 'project.create.no-program' | translate }}
                </option>
                <option *ngFor="let program of programs"
                        [disabled]="expiredProgram(program.endDateTime)"
                        [ngValue]="program.id">
                  {{ program.name }} <span *ngIf="expiredProgram(program.endDateTime)">
                    ({{ submittedToProgram(program.id) ?
                      ('project.create.submitted' | translate) : ('project.create.past-submission-date' | translate) }})
                  </span>
                </option>
              </select>
            </div>
          </div>
        </ng-container>

        <!-- Status -->
        <ng-container *ngIf="specificationFormRule?.fields?.status?.display">
          <div class="input-50" [ngClass]="{ 'input-33' : programs?.length > 0 }">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.status?.required }" for="status">
              {{ 'project.create.status' | translate }}
            </label>
            <div class="select-wrapper">
              <select formControlName="statusControl"
                    class="form-control"
                    id="status"
                    data-testid="project-status"
                    [ngClass]="isDefaultValue('statusControl')">
                <option value="">{{ 'form.select-default' | translate }}</option>
                <option *ngFor="let projectStatusType of projectStatusTypes"
                        [ngValue]="projectStatusType.id">
                  {{ projectStatusType.code | translate }}
                </option>
              </select>
            </div>
          </div>
        </ng-container>

        <!-- COD -->
        <ng-container *ngIf="specificationFormRule?.fields?.cod?.display">
          <div class="input-50" [ngClass]="{ 'input-33' : programs?.length > 0 }">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.cod?.required }" for="cod">
              {{ 'project.create.' + specificationFormRule?.fields?.cod?.headingKey | translate }}
            </label>
            <div class="calendar-wrapper">
              <input
                type="text"
                id="cod"
                data-testid="project-calendar"
                formControlName="codControl"
                class="form-control"
                autocomplete="off"
                [placeholder]="'calendar.format' | translate"
                bsDatepicker
                [bsConfig]="bsConfig"
                placement="top">
            </div>
            <oes-form-error-message
              [showRequired]="specificationFormGroup?.controls?.codControl?.touched &&
                              specificationFormGroup?.controls?.codControl?.invalid">
            </oes-form-error-message>
          </div>
        </ng-container>

        <!-- Description -->
        <ng-container *ngIf="specificationFormRule?.fields?.description?.display">
          <div class="input-100">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.description?.required }" for="description">
              {{ 'project.create.description' | translate }}
            </label>
            <oes-quill
              id="description"
              class="style-overhaul-flag"
              data-testid="project-description"
              formControlName="descriptionControl"
              [placeholder]="'project.create.executive-summary' | translate"
              [editorDisabled]="readOnly"
              [initialValue]="project?.description || ''">
            </oes-quill>
          </div>
        </ng-container>

      </div>
    </section>

    <section *ngIf="specificationFormRule?.fields?.address1?.display ||
                    specificationFormRule?.fields?.address2?.display ||
                    specificationFormRule?.fields?.country?.display ||
                    specificationFormRule?.fields?.state?.display ||
                    specificationFormRule?.fields?.city?.display ||
                    specificationFormRule?.fields?.postalCode?.display ||
                    specificationFormRule?.fields?.latitude?.display ||
                    specificationFormRule?.fields?.longitude?.display">
      <h4>{{ 'project.create.' + specificationFormRule?.keys?.location | translate }}</h4>
      <div class="location-inputs">

        <!-- Address 1 -->
        <ng-container *ngIf="specificationFormRule?.fields?.address1?.display">
          <div class="input-50">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.address1?.required }" for="address1">
              {{ 'project.create.address-1' | translate }}
            </label>
            <input
              type="text"
              class="form-control"
              id="address1"
              data-testid="project-address-1"
              formControlName="address1Control">
          </div>
        </ng-container>

        <!-- Address 2 -->
        <ng-container *ngIf="specificationFormRule?.fields?.address2?.display">
          <div class="input-50">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.address2?.required }" for="address2">
              {{ 'project.create.address-2' | translate }}
            </label>
            <input
              type="text"
              class="form-control"
              id="address2"
              data-testid="project-address-2"
              formControlName="address2Control">
          </div>
        </ng-container>

        <!-- Country -->
        <ng-container *ngIf="specificationFormRule?.fields?.country?.display">
          <div class="input-33">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.country?.required }" for="country">
              {{ 'project.create.country' | translate }}
            </label>
            <div class="select-wrapper">
              <select formControlName="countryControl"
                      id="country"
                      data-testid="project-country"
                      class="form-control"
                      [ngClass]="isDefaultValue('countryControl')"
                      (change)="onCountryChanged()">
                <option value="">{{ 'form.select-default' | translate }}</option>
                <option *ngFor="let country of countries"
                        [ngValue]="country.id">
                  {{ country.name }}
                </option>
              </select>
            </div>
            <oes-form-error-message
              [showRequired]="specificationFormGroup?.controls?.countryControl?.touched &&
                              specificationFormGroup?.controls?.countryControl?.invalid">
            </oes-form-error-message>
          </div>
        </ng-container>

        <!-- State/Province -->
        <ng-container *ngIf="specificationFormRule?.fields?.state?.display">
          <div class="input-33 state">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.state?.required }" for="region">
              {{ 'project.create.region' | translate }}
            </label>
            <div class="select-wrapper">
              <select formControlName="regionControl"
                      id="region"
                      data-testid="project-region"
                      class="form-control"
                      [ngClass]="isDefaultValue('regionControl')">
                <option value="">{{ 'form.select-default' | translate }}</option>
                <option *ngFor="let region of regions"
                        [ngValue]="region.id">
                  {{ region.name }}
                </option>
              </select>
            </div>
          </div>
        </ng-container>

        <!-- City -->
        <ng-container *ngIf="specificationFormRule?.fields?.city?.display">
          <div class="input-33">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.city?.required }" for="city">
              {{ 'project.create.city' | translate }}
            </label>
            <input
              id="city"
              data-testid="project-city"
              type="text"
              class="form-control"
              formControlName="cityControl">
            <oes-form-error-message
              [showRequired]="specificationFormGroup?.controls?.cityControl?.touched &&
                              specificationFormGroup?.controls?.cityControl?.invalid">
            </oes-form-error-message>
          </div>
        </ng-container>

        <!-- PostalCode -->
        <ng-container *ngIf="specificationFormRule?.fields?.postalCode?.display">
          <div class="input-33">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.postalCode?.required }" for="postalCode">
              {{ 'project.create.postal-code' | translate }}
            </label>
            <input
              type="text"
              class="form-control"
              id="postalCode"
              data-testid="project-postal-code"
              formControlName="postalCodeControl">
          </div>
        </ng-container>

        <!-- Latitude -->
        <ng-container *ngIf="specificationFormRule?.fields?.latitude?.display">
          <div class="input-33">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.latitude?.required }" for="latitude">{{ 'project.create.latitude' | translate }}</label>
            <input
              type="number"
              data-testid="project-latitude"
              id="latitude"
              class="form-control"
              formControlName="latitudeControl">
            <oes-form-error-message
              [showRequired]="specificationFormGroup?.controls?.latitudeControl?.touched &&
                              specificationFormGroup?.controls?.latitudeControl?.invalid">
            </oes-form-error-message>
          </div>

        </ng-container>

        <!-- Longitude -->
        <ng-container *ngIf="specificationFormRule?.fields?.longitude?.display">
          <div class="input-33">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.longitude?.required }" for="longitude">{{ 'project.create.longitude' | translate }}</label>
            <input
              type="number"
              data-testid="project-longitude"
              id="longitude"
              class="form-control"
              formControlName="longitudeControl">
            <oes-form-error-message
              [showRequired]="specificationFormGroup?.controls?.longitudeControl?.touched &&
                              specificationFormGroup?.controls?.longitudeControl?.invalid">
            </oes-form-error-message>
            <ng-container *ngIf="specificationFormRule?.fields?.map?.display">
              <p class="check-location" (click)="checkLocation()">{{ 'project.create.check-location' | translate }}<img src="/assets/icons/line-icons/shared/orange/arrow-up-right.svg" /></p>
            </ng-container>
          </div>
        </ng-container>

      </div>
    </section>

    <section *ngIf="specificationFormRule?.fields?.currency?.display ||
                    specificationFormRule?.fields?.exchangeRate?.display">
      <h4>{{ 'project.create.' + specificationFormRule?.keys?.currency | translate }}</h4>
      <div class="currency-inputs">

        <!-- Currency -->
        <ng-container *ngIf="specificationFormRule?.fields?.currency?.display">
          <div class="input-33">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.currency?.required }" for="alternate-currency">
              {{ 'project.create.' + specificationFormRule?.fields?.currency?.headingKey | translate }}
            </label>
            <div class="select-wrapper">
              <select id="alternate-currency"
                    data-testid="project-alternate-currency"
                    class="form-control"
                    formControlName="currencyControl"
                    (change)="onCurrencyChanged()">
                <option *ngFor="let currencyType of currencyTypes"
                      [ngValue]="currencyType.id">{{ 'currency-type.' + currencyType.id | translate }}
                </option>
              </select>
            </div>
          </div>
        </ng-container>

        <!-- Exchange rate -->
        <ng-container *ngIf="specificationFormRule?.fields?.exchangeRate?.display">
          <div class="input-33">
            <label [ngClass]="{ 'required' : specificationFormRule?.fields?.exchangeRate?.required }" for="exchange-rate">
              {{ 'project.create.exchange-rate' | translate }}
              <a class="view-current-rates" (click)="showExchangeRates()">
                {{ 'project.create.exchange-rate-link' | translate }}
              </a>
            </label>
            <input
              type="number"
              data-testid="project-exchange-rate"
              id="exchange-rate"
              class="form-control"
              min="0"
              formControlName="exchangeRateControl">
            <oes-form-error-message
              [showInvalid]="specificationFormGroup?.controls?.countryControl?.dirty &&
                            specificationFormGroup?.controls?.exchangeRateControl?.errors?.validNumber"
              [text]="'form.error.greater-than-zero' | translate">
            </oes-form-error-message>
          </div>
        </ng-container>

      </div>
    </section>
  </form>

  <!-- buttons -->
  <ng-container *ngIf="!readOnly">
    <div class="buttons d-flex flex-row justify-content-end">
      <ng-container *ngIf="cancelButton">
        <button type="button"
                class="btn btn-secondary"
                data-testid="cancel-create-project-button"
                (click)="cancel()">
          {{ 'buttons.cancel' | translate }}
        </button>
      </ng-container>
      <button type="submit"
              class="btn btn-primary"
              data-testid="save-new-project-button"
              [disabled]="!specificationFormGroup?.valid || !specificationFormGroup.dirty"
              (click)="onSubmit()">
        {{ 'buttons.' + submitButtonName | translate }}
      </button>
    </div>
  </ng-container>

</ng-container>

<!-- Google Maps Modal -->
<ng-container *ngIf="specificationFormRule?.fields?.map?.display">
  <oes-modal-dialog
    #googleMapsModal
    [title]="'project.create.map' | translate"
    [allowCancel]="true"
    [allowSubmit]="false"
    [cancelButtonText]="'buttons.close' | translate"
    [size]="'small'">
    <google-map
      height="300px"
      [options]="mapOptions"
      (mapClick)="addMarker($event)">
      <map-marker
        [position]="markerPosition"
        [options]="markerOptions">
      </map-marker>
    </google-map>
  </oes-modal-dialog>
</ng-container>

<!-- Exchange Rate Modal -->
<ng-container *ngIf="specificationFormRule?.fields?.exchangeRate?.display">
  <oes-modal-dialog
    #exchangeRateModal
    [title]="'project.create.exchange-rate-modal.title' | translate"
    [allowCancel]="true"
    [allowSubmit]="false"
    [size]="'small'">
    <table>
      <tr>
        <th>{{ 'project.create.code' | translate }}</th>
        <th>{{ 'project.create.currency' | translate }}</th>
        <th>{{ 'project.create.exchange-rate-per-usd' | translate }}</th>
        <th>{{ 'project.create.date' | translate }}</th>
      </tr>
      <tr *ngFor="let currencyExchange of exchangeRates">
        <td>{{ currencyExchange.code }}</td>
        <td>{{ 'currency-type.'+currencyExchange.code | translate }}</td>
        <td>{{ currencyExchange.exchangeRate }}</td>
        <td>{{ currencyExchange.quoteDateTime | date: 'yyyy-MM-dd' }}</td>
      </tr>
    </table>
  </oes-modal-dialog>
</ng-container>
