import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isObject } from 'lodash-es';

import { ValidationType } from './validation-type.enum';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {
  private file;
  private saveButton = {
    label: this._translateService.instant('buttons.save'),
    key: 'submit',
    type: 'button',
    action: 'event',
    theme: 'primary',
    input: true,
    tableView: true,
    mask: false,
    alwaysEnabled: false,
    showValidations: false,
    event: 'save'
  };
  private submitButton = {
    label: this._translateService.instant('buttons.submit-application'),
    key: 'submit',
    type: 'button',
    theme: 'primary',
    disableOnInvalid: true,
    input: true,
    tableView: true,
    customConditional: ''
  };
  private validateButton = {
    label: this._translateService.instant('buttons.validate'),
    key: 'submit',
    type: 'button',
    action: 'event',
    theme: 'primary',
    input: true,
    tableView: true,
    mask: false,
    alwaysEnabled: false,
    showValidations: false,
    event: 'validate'
  };
  private errorMessage = {};

  constructor(private _translateService: TranslateService) {
    this.errorMessage = this._translateService.instant('form.error');
  }

  /**
   * Check this.file.name contains the id or not
   * @param id: string
   */
  public isOriginalJson(id: string) {
    return this.file.name.includes(id);
  }

  /**
   * Store input values to json.data. It will pass as [submission]="json.data" when formio is loaded
   * @param event: Formio event which is field key and value object
   */
  public updateDefaultValue(json: any, event: any) {
    // insert event.data to the original json
    if (event.data) {
      json['data'] = event.data;
    }
    return json;
  }

  /**
   * (Unusable) Formio form uploader testing
   * @param components: any[]
   * @param path: string
   */
  private findUploadComponents(components: any[], path: string): any {
    components.forEach((component, index) => {
      if (component.key === 'upload') {
        // add/overwrite storage and url
        components[index]['storage'] = 'url';
        components[index]['url'] = path;
      }
      if (component.components) {
        const updatedComponents = this.findUploadComponents(component.components, path);
        components[index].components = updatedComponents;
      }
    });
    return components;
  }

  /**
   * Check the json has Submit button, if not add a submit button and show condition
   *    "customConditional": "show = (data.q1 !== '' && data.q4 && data.q4.aaa && data.q4.bbb && data.q4.aaa !== '');",
   * @param json: {}
   */
  // remove submit button because we have our submit button now.
  public checkSubmitButton(json: {}): any {
    const newJson = this.removeButton(json, 'Submit');
    // generate conditions
    // const condition = 'show = (' + this.generateCustomCondition(newJson).join(' && ') + ');';
    // this.submitButton['customConditional'] = condition;
    // newJson['components'].push(this.submitButton);
    // // remove undefined
    // newJson['components'] = newJson['components'].filter(item => item);
    return newJson;
  }

  /**
   * Check the json has Save button, if not add a save button
   * @param json: {}
   */
  public checkSaveButton(json: {}): {} {
    const newJson = this.removeButton(json, 'Save');
    newJson['components'].splice(0, 0, this.saveButton); // unshift made a compile error
    newJson['components'].push(this.saveButton);
    // remove undefined
    newJson['components'] = newJson['components'].filter(item => item);
    return newJson;
  }

  public checkValidateButton(json: {}): {} {
    const newJson = this.removeButton(json, 'Validate');
    // business team wants to remove Validate button, but I'll keep a code just in case.
    // newJson['components'].push(this.validateButton);
    return newJson;
  }

  // recursively search and remove button
  private removeButton(json: {}, label: string): {} {
    const newJson = json;
    if (newJson['components'] && newJson['components']?.length > 0) {
      newJson['components'].forEach((component, index) => {
        if (component['type'] === 'button' && component['label'].toLowerCase() === label.toLowerCase()) {
          newJson['components'].splice(index, 1);
        } else if (component['components']) {
          const updatedComponents = this.removeButton(component, label);

          if (updatedComponents) {
            newJson['components'][index]['components'] = updatedComponents['components'];
          }
        }
      });
      return newJson;
    }
  }

  /**
   * To reduce json size. intended increasing a loading speed but it may not help so much.
   */
  public removeBlankNodes(json: {}): {} {
    const newJson = json;
    if (newJson['components'] && newJson['components']?.length > 0) {
      newJson['components'].forEach((component, index) => {
        for (const item in component) {
          if (item !== 'defaultValue') {
            if (newJson['components'][index][item] === null ||
                newJson['components'][index][item] === undefined ||
                newJson['components'][index][item] === '') {
              delete newJson['components'][index][item];
            } else if (item === 'components') {
              if (component['components']?.length > 0) {
                const updatedComponents = this.removeBlankNodes(component);
                if (updatedComponents['components'] && updatedComponents['components']?.length > 0) {
                  newJson['components'][index][item] = updatedComponents['components'];
                }
              } else {
                delete newJson['components'][index][item];
              }
            }
          }
        }
      });
      return newJson;
    }
  }

  /** NOTE: we removed formio submit button. This may not need anymore
   * Generate customCondition for Submit button.
   * Check validate.required: true
   */
  // private generateCustomCondition(json: {}): string[] {
  //   let conditions: string[] = [];
  //   if (json['components'] && json['components'].length > 0) {
  //     conditions = this.checkNestedItem(json['components']);
  //   }
  //   if (json['columns'] && json['columns'].length > 0) {
  //     conditions = this.checkNestedItem(json['columns']);
  //   }
  //   return conditions;
  // }

  private checkNestedItem(items: any): string[] {
    let conditions: string[] = [];
    items.forEach((item, index) => {
      if (item['validate'] && item['validate']['required']) {
        // check a key and key condition
        conditions.push('data.' + item['key']);
        // TODO: need to support other types
        switch (item['type']) {
          case 'textfield':
          case 'textarea':
            conditions.push('data.' + item['key'] + '!==\'\'');
            conditions.push('data.' + item['key'] + '!==\'<p><br></p>\'');
            conditions.push('data.' + item['key'] + '!==\'<p>&nbsp;</p>\'');
            break;
          case 'checkbox':
            conditions.push('data.' + item['key'] + '===true');
            break;
        }
      }
      if (item['components'] && item['components']?.length > 0) {
        const newConditions = this.checkNestedItem(item['components']);
        if (newConditions && newConditions.length > 0) {
          conditions = [...conditions, ...newConditions];
        }
      }
      if (item['columns'] && item['columns']?.length > 0) {
        const newConditions = this.checkNestedItem(item['columns']);
        if (newConditions && newConditions?.length > 0) {
          conditions = [...conditions, ...newConditions];
        }
      }
    });
    return [...conditions];
  }

  /**
   * Check required fields are filled or not.
   * @param json: {}
   */
  public checkRequiredFields(json: any): boolean {
    const errors = this.checkValidationType(json.components, json.data);
    return errors?.length === 0;
  }

  // this is to support old form which stores value in defaultValue. We are storing values in json['data'] now.
  public exportDefaultValues(json: any): any {
    let result = {};
    if (json.components && json.components?.length > 0) {
      const value = this.getDefaultValue(json.components);
      if (value) {
        result = {...result, ...value};
      }
    }
    if (json.columns && json.columns?.length > 0) {
      result = {...result, ...this.getDefaultValue(json.columns)};
    }
    return result;
  }

  private getDefaultValue(items: any): any {
    let data = {};
    if (items && items?.length > 0) {
      items.forEach(item => {
        // sometime items has null.
        if (item) {
          if (item.defaultValue && item.defaultValue !== '') {
            data[item.key] = item.defaultValue;
            // remove old value because we don't store a data in here anymore.
            item.defaultValue = '';
          }
          if (item.components && item.components?.length > 0) {
            const nestedResult = this.getDefaultValue(item.components);
            if (nestedResult) {
              data = {...data, ...nestedResult};
            }
          }
          if (item.columns && item.columns?.length > 0) {
            const nestedResult = this.getDefaultValue(item.columns);
            if (nestedResult) {
              data = {...data, ...nestedResult};
            }
          }
        }
      });
    }
    return data;
  }

  public cleaningSaveButton(json: any): any {
    const newJson = json;
    if (json && json.components) {
      json.components.forEach((item, index) => {
        if (item && item.event === 'save' && item.disableOnInvalid) {
          // over write with false
          newJson.components[index]['disableOnInvalid'] = false;
        }
      });
    }
    return newJson;
  }

  public validate(components: any, data: any): any[] {
    return this.checkValidationType(components, data);
  }

  public moveSaveButtons(targetContainerTop: HTMLElement, targetContainerBottom: HTMLElement) {
    let btnContainers: NodeListOf<HTMLElement> = this.getSaveButtonContainers();
    if (!btnContainers || btnContainers.length < 2) {
      return;
    }
    const btnContainerTop = btnContainers[0];
    const btnContainerBottom = btnContainers[btnContainers.length - 1];
    const saveBtnTop = btnContainerTop.firstElementChild;
    const saveBtnBottom = btnContainerBottom.firstElementChild;
    if (saveBtnTop && saveBtnBottom) {
      saveBtnTop.classList.remove('btn-primary');
      saveBtnTop.classList.add('btn-primary-light');
      saveBtnBottom.classList.remove('btn-primary');
      saveBtnBottom.classList.add('btn-primary-light');
      targetContainerTop.insertBefore(btnContainerTop, targetContainerTop.firstChild);
      targetContainerBottom.insertBefore(btnContainerBottom, targetContainerBottom.firstChild);
    }
  }

  private getSaveButtonContainers(): NodeListOf<HTMLElement> {
    let btnContainers: NodeListOf<HTMLElement> = document.querySelectorAll('.formio-component-button');
    return this.cleanupOlderSaveContainers(btnContainers);
  }

  private cleanupOlderSaveContainers(btnContainers: NodeListOf<HTMLElement>): NodeListOf<HTMLElement> {
    // When switching from 'Attachments' tab to 'Form' tab new default save buttons are populated,
    // Therefore we need to remove the old ones to avoid conficts and duplication.
    if (btnContainers.length > 2) {
      btnContainers[0].remove();
      btnContainers[btnContainers.length - 1].remove();
      return document.querySelectorAll('.formio-component-button');
    } else {
      return btnContainers;
    }
  }

  private checkValidationType(components: any, data: any): any[] {

    if (!data) {
      return [];
    }
    let invalids = [];
    components.forEach(component => {
      if (component.key !== 'submit') {
        if (component.validate?.custom && component.validate?.custom !== '') {
          const matched = component.validate?.custom.match(/\d/);
          if (matched?.length > 0) {
            if (this.invalidMaxLength(matched[0], data[component.key])) {
              invalids.push({
                label: component.label,
                type: ValidationType.maxLength,
                value: matched[0]
              });
            }
          }
        }
        // max word count
        if (component.validate?.maxWords && component.validate?.maxWords !== '') {
          if (this.invalidMaxWordLength(component.validate?.maxWords, data[component.key])) {
            invalids.push({
              label: component.label,
              type: ValidationType.maxWordLength,
              value: component.validate?.maxWords
            });
          }
        }
        // Required
        if (component.validate?.required === true) {
          if (component.type === 'file') {
            if (!component.conditional || ((data[component.conditional.when]?.toString() === component.conditional.eq) === component.conditional.show)) {
              if (!data[component.key]?.length) {
                invalids.push({
                  label: component.label,
                  type: ValidationType.required,
                  value: ''
                });
              }
            }
          } else {
            if (!component.conditional || ((data[component.conditional.when]?.toString() === component.conditional.eq) === component.conditional.show)) {
              if (data === undefined ||
                data[component.key] === undefined ||
                data[component.key] === '' ||
                data[component.key] === false) {
                  invalids.push({
                    label: component.label,
                    type: ValidationType.required,
                    value: ''
                  });
              }
            }
          }
        }
        // for nested components
        if (component.components?.length > 0) {
          if (!component.conditional || ((data[component.conditional.when]?.toString() === component.conditional.eq) === component.conditional.show)) {
            const result = this.checkValidationType(component.components, data);
            if (result?.length > 0) {
              invalids = invalids.concat(result);
            }
          }
        }
        // for nested columns
        if (component.columns?.length > 0) {
          if (!component.conditional || ((data[component.conditional.when]?.toString() === component.conditional.eq) === component.conditional.show)) {
            const result = this.checkValidationType(component.columns, data);
            if (result?.length > 0) {
              invalids = invalids.concat(result);
            }
          }
        }
        if (component.rows?.length > 0) {
          if (!component.conditional || ((data[component.conditional.when]?.toString() === component.conditional.eq) === component.conditional.show)) {
            component.rows.forEach(row => {
              row.forEach(cell => {
                const result = this.checkValidationType(cell.components, data);
                if (result?.length > 0) {
                  invalids = invalids.concat(result);
                }
              });
            });
          }
        }
      }
    });
    return invalids;
  }

  private invalidMaxLength(limit: number, value: string): boolean {
    return this.stripHtml(value)?.length > limit;
  }

  private invalidMaxWordLength(limit: number, value: string): boolean {
    return (this.stripHtml(value).split(' ').filter(wd => wd?.length > 0))?.length > limit;
  }

  private stripHtml(text: string) {
    let plainText = '';
    if (text && text !== '') {
      plainText = text.replace(/<p>&nbsp;<\/p>/, '').replace(/<[^>]*>/g, '').replace(/\n/gi, '').replace(/&nbsp;/g, ' ');
    }
    return plainText;
  }
}
